import React, { memo } from 'react'
import {
  BWLink,
  BWPanel,
  BWPanelContent,
  BWText,
  BWIcon
} from 'src/libs/qb-brand-web-components'
import { Link } from 'react-router-dom'
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons'

import { OverviewTitleContent } from '../../../components/shared/common'
import { EARN_POINTS_URL } from '../../../constants/navigation'
import Button from '../../../components/shared/button/Button'
import BalanceWithAnimation from '../../../components/shared/overview/BalanceWithAnimation'

const OverviewMainPanelWrapper = ({
  onEnterRewardCodeClick,
  currentUserPoints,
  previousUserPoints,
  t,
  panelCss,
  navItemsMoreLength
}) => {
  return (
    <BWPanel padding={15} css={[{ maxHeight: '500px' }, panelCss]}>
      <OverviewTitleContent
        navItemsMoreLength={navItemsMoreLength}
        css={{ padding: 25, gap: 40 }}
      >
        <Link to={EARN_POINTS_URL}>
          <Button label={t('brand:welcome.earn-points')} type='button' />
        </Link>
        <BWPanelContent css={{ gap: 5 }}>
          <BWIcon
            icon={faBullseyeArrow}
            faIconCss={{ fontSize: 60, marginBottom: 15 }}
          />
          <BWText label={t('brand:reward.current-point-balance')} size={21} />
          <BalanceWithAnimation
            previousUserPoints={previousUserPoints}
            currentUserPoints={currentUserPoints}
            fontSize={32}
          />
        </BWPanelContent>
        <BWText
          alignCenter
          label={
            <>
              <BWText label={t('brand:welcome.have-a-reward-code')} />
              <BWLink
                label={t('brand:welcome.receive-your-reward')}
                onClick={onEnterRewardCodeClick}
              />
            </>
          }
          as='div'
        />
      </OverviewTitleContent>
    </BWPanel>
  )
}

export default memo(OverviewMainPanelWrapper)
