import {
  BW_COLOR_LITE_WHITE,
  BW_LANGUAGE_EN
} from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'moda_health',
  name: 'Moda Health',
  faqEmail: 'earnmodarewards@modahealth.com',
  websiteUrl: 'https://www.modahealth.com/',
  privacyPolicyUrl: 'https://www.modahealth.com/privacy/index.shtml',
  termsUrl: 'https://earnmodarewards.com/static/assets/terms_conditions.pdf',
  theme: {
    colors: {
      main: '#B80175',
      accent: '#0098AA'
    }
  },
  supportedLanguages: [BW_LANGUAGE_EN],
  features: {
    singleCoeStoreFront: true,
    enableImpactReport: true
  },
  cognito: {
    region: 'us-east-1',
    poolId: 'us-east-1_WPlUzumEu',
    clientId: '5s9uuqfokds6h9krm9jrgc1bt9',
    poolDomain: 'moda-health.auth.us-east-1.amazoncognito.com',
    redirectUri: 'https://earnmodarewards.com/login'
  },
  gaTrackingId: 'G-0HSDP6HRXS',
  hotjarId: '2876197',
  facebookPixelId: '',
  prefIconColor: BW_COLOR_LITE_WHITE,
  linkTextDecoration: 'underline',
  membershipAgreementUrl:
    'https://earnmodarewards.com/static/assets/membership_agreement.pdf'
}

export default brandConfig
