import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import EarnPoints from '../components/earnPoints/EarnPoints'

const EarnPointsContainer = ({
  tokenProfile,
  mainProfile: { brandAppConfig }
}) => {
  const { t } = useTranslation()
  return (
    <EarnPoints
      cashbackEnabled={brandAppConfig.cashbackEarnOption}
      tokenProfile={tokenProfile}
      t={t}
    />
  )
}

const mapStateToProps = ({ mainReducer }) => {
  const { mainProfile, tokenProfile } = mainReducer
  return {
    mainProfile,
    tokenProfile
  }
}

export default connect(mapStateToProps, null)(EarnPointsContainer)
