import { DYNAMIC_PAGE_REDIRECT_PATHS } from '../../../constants/externalUrls'
import { REDIRECT_URL } from '../../../constants/navigation'
import { isUrlPatternMatching, toLower } from '../../../util/string.helpers'
import { EAGLES_REF } from '../constants/app'

const truthbrushUrl = () => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.TRUTHBRUSH}`
}

const zDentalUrl = () => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.Z_DENTAL}`
}

const buildRedemptionUrl = (url) => {
  if (isUrlPatternMatching(url, 'moda.truthbrush.com')) {
    return truthbrushUrl()
  } else if (isUrlPatternMatching(url, 'myzsonic.com/moda')) {
    return zDentalUrl()
  }

  return url
}

const isWelcomeRefMatching = (currentRef) => {
  return EAGLES_REF.some((ref) => toLower(ref) === toLower(currentRef))
}

export { truthbrushUrl, zDentalUrl, buildRedemptionUrl, isWelcomeRefMatching }
