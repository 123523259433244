import React, { memo } from 'react'

import carePos from '../assets/rewards/care-pos.jpg'
import toothbrush from '../assets/rewards/toothbrush.jpg'
import truthbrushTracker from '../assets/rewards/truthbrush-tracker.png'
import EarnRewardCards from '../../../components/shared/redeem/EarnRewardCards'
import { zDentalUrl, truthbrushUrl } from '../util/brand.helpers'

// @TODO: the following design can be reused for the other LWAs in future.
// For now using only for moda_health LWA.
const HowToEarnRewardsWrapper = ({ t, tokenSymbol, ...restProps }) => {
  const earnOptionsData = [
    {
      label: 'buy-dental-and-vision',
      description: `1 USD = 10 ${tokenSymbol}`,
      image: carePos,
      link: 'https://moda.telemedsimplified.com'
    },
    {
      label: 'buy-zdental',
      description: `1 USD = 10 ${tokenSymbol}`,
      image: toothbrush,
      link: zDentalUrl()
    },
    {
      label: 'buy-truthbrush-tracker',
      description: `1 USD = 10 ${tokenSymbol}`,
      image: truthbrushTracker,
      link: truthbrushUrl()
    },
    {
      label: 'brush-your-teeth',
      description: `10 ${tokenSymbol} / day`,
      image: truthbrushTracker,
      link: truthbrushUrl()
    }
  ]

  return <EarnRewardCards data={earnOptionsData} t={t} {...restProps} />
}

export default memo(HowToEarnRewardsWrapper)
